// 引入 Vue 和 VueRouter  
import { createRouter, createWebHistory } from 'vue-router';  
  
// 引入你的组件  
import Home from './views/home/index.vue';  
  
// 定义路由  
const routes = [  
  {  
    path: '/',  
    name: 'Home',  
    component: Home  
  },  
  // ... 其他路由  
];  
  
// 创建路由实例  
const router = createRouter({  
  history: createWebHistory(), // 创建一个 history 实例  
  routes, // 简写，相当于 routes: routes  
});  
  
// 导出路由实例  
export default router;