<style lang="scss" scoped>
.headerWrapper {
  height: 80px;
}

.header {
  height: 80px;
  background-color: #17B999;
  color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 80px;
  z-index: 100;
  position: relative;

  .container {
    height: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #DCDFE6;
  }


  h1 {
    margin: 0;
    float: left;
    font-size: 32px;
    font-weight: normal;

    a {
      color: #333;
      text-decoration: none;
      display: block;
    }

    span {
      font-size: 12px;
      display: inline-block;
      width: 34px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      vertical-align: middle;
      margin-left: 10px;
      border-radius: 3px;
    }
  }

  .nav {
    float: right;
    height: 100%;
    line-height: 80px;
    background: transparent;
    padding: 0;
    margin: 0;
    margin-left: 50px;
    margin-right: 50px;
    &::before,
    &::after {
      display: table;
      content: "";
    }

    &::after {
      clear: both;
    }
  }

  .nav-gap {
    position: relative;
    width: 1px;
    height: 80px;
    padding: 0 20px;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 8px);
      width: 1px;
      height: 16px;
      background: #ebebeb;
    }
  }

  .logoBox {
    padding-left: 15px;
    height: 80px;
    display: flex;
    align-items: center;
    .nav-logo {
      height: 60px;
    }
  }



  .nav-item {
    margin: 0;
    float: left;
    list-style: none;
    position: relative;
    cursor: pointer;

    &.nav-algolia-search {
      cursor: default;
    }


    a {
      text-decoration: none;
      color: #fff;
      display: block;
      padding: 0 22px;

      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }
}


.example-showcase .el-dropdown-link {
  font-size: 12px;
  display: flex;
  align-items: center;

  &.active,
  &:hover {
    border: 0;
  }
}
</style>
<template>
  <div class="headerWrapper">
    <header class="header" ref="header">
      <div class="container">
        <h1><router-link :to="`/home`">
            <!-- logo -->
            <div class="logoBox">
              <img src="../assets/logo.png" alt="element-logo" class="nav-logo">
            </div>
          </router-link></h1>

        <!-- nav -->
        <ul class="nav">
          <li class="nav-item nav-algolia-search" v-show="isComponentPage">
            <algolia-search></algolia-search>
          </li>
          <li class="nav-item">
            <router-link active-class="active" :to="`/`">WeLink是什么
            </router-link>
          </li>
          <li class="nav-item">
            <router-link active-class="active" :to="`/`">未来
            </router-link>
          </li>
          <li class="nav-item nav-item-theme">
            <router-link active-class="active" :to="`/`">优势
            </router-link>
          </li>
          <li class="nav-item nav-item-theme">
            <router-link active-class="active" :to="`/`">探索
            </router-link>
          </li>


          <!-- 语言选择器 -->
          <li class="nav-item logoBox">
            <el-dropdown trigger="click">
              <div class="el-dropdown-link" style="color: #fff;">
                社交媒体
              </div>
              <template #dropdown>
                <el-dropdown-menu >
                  <el-dropdown-item>Twitter</el-dropdown-item>
                  <el-dropdown-item>Telegram Chat</el-dropdown-item>
                  <el-dropdown-item>Telegram Channel</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </li>


          <li class="nav-item nav-item-theme">
            <router-link active-class="active" :to="`/`">Gitbook</router-link>
          </li>

          <!-- 语言选择器 -->
          <li class="nav-item logoBox">
            <el-dropdown trigger="click">
              <div class="el-dropdown-link" style="color: #fff;">
                Langage
              </div>
              <template #dropdown>
                <el-dropdown-menu >
                  <el-dropdown-item @click="changeLanguage('zh')">中文</el-dropdown-item>
                  <el-dropdown-item @click="changeLanguage('en')">英文</el-dropdown-item>
                  <el-dropdown-item @click="changeLanguage('ru')">俄文</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </li>



        </ul>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      displayedLang: '中文',
      langs: {
        'zh-CN': '中文',
        'en-US': 'English',
        'es': 'Español',
        'fr-FR': 'Français'
      }
    };
  },
  components: {

  },
  methods: {
    changeLanguage(lang) {
      console.log('ddddd',lang)
      this.$i18n.locale = lang;
    }
  }
}
</script>
