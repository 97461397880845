<template>
  <div>
    <div class="header">
      <div class="center">
        <div class="left">
          <a href="" class="logo">
            <img src="../../assets/index/logo.svg" alt="" />
          </a>
        </div>
        <div class="right">
          <div class="menu">
            <a href="#" v-scroll-to="'#whatis'" class="a_item">What is STON.fi</a>
            <a href="#" v-scroll-to="'#explore'" class="a_item">Explore</a>
            <a href="#" v-scroll-to="'#developers'" class="a_item">Developers</a>
            <a href="http://www.baidu.com/" target="_blank" class="a_item"><i class="icon_share"></i>Blog</a>
          </div>
          <div class="language a_item">
<!--            <i class="icon_flag"></i>-->
            <span>{{selectedLanguage}}</span>
            <i class="icon_down"></i>
            <div class="select_wrap">
              <div class="language_list">
                <div class="language_item" @click="setLanguage('en')" :class="{ selected: selectedLanguage === 'en' }">
                  English
                  <i v-if="selectedLanguage === 'en'">✔️</i>
                </div>
                <div class="language_item" @click="setLanguage('zh')" :class="{ selected: selectedLanguage === 'zh' }">
                  中文
                  <i v-if="selectedLanguage === 'zh'">✔️</i>
                </div>
                <div class="language_item" @click="setLanguage('ru')" :class="{ selected: selectedLanguage === 'ru' }">
                  Русский
                  <i v-if="selectedLanguage === 'ru'">✔️</i>
                </div>
                <div class="language_item" @click="setLanguage('id')" :class="{ selected: selectedLanguage === 'id' }">
                  Bahasa Indonesia
                  <i v-if="selectedLanguage === 'id'">✔️</i>
                </div>
                <div class="language_item" @click="setLanguage('vi')" :class="{ selected: selectedLanguage === 'vi' }">
                  Tiếng Việt
                  <i  v-if="selectedLanguage === 'vi'">✔️</i>
                </div>

              </div>
            </div>
          </div>
          <div class="download_app a_item">
            <i class="icon_share2"></i>
            <span>Go to App</span>
          </div>
          <div class="bt1" v-if="!showMenu" @click="menuTap()"></div>
          <div class="bt2" v-else @click="menuTap()"></div>
        </div>
      </div>
    </div>

    <div class="mobile_menu" :class="showMenu ? 'target' : ''" @click="menuTap()">
      <a href="#" v-scroll-to="'#whatis'" class="mobile_menu_link">What is STON.fi</a>
      <a href="#" v-scroll-to="'#explore'" class="mobile_menu_link">Explore</a>
      <a href="#" v-scroll-to="'#developers'" class="mobile_menu_link">Developers</a>
      <a href="http://www.baidu.com" target="_blank" class="mobile_menu_link"><i class="icon_share"></i>Blog</a>
    </div>

    <div class="banner">
      <div class="video">
        <video src="../../assets/index/video.webm" autoplay="true" loop="true" muted="true" poster="../../assets/index/poster2.jpg"></video>
        <div class="cover_left"></div>
        <div class="cover_right"></div>
        <div class="cover_bottom"></div>
        <div class="cover_text">


          <div class="t1">{{ $t('Instantly swap Toncoin') }}</div>
          <div class="t2">{{ $t('for any coin or token at the best rate') }}</div>
          <div class="btns">
            <div class="b1"><i class="icon_share2"></i>{{ $t('Go to App') }}</div>
            <div class="b2">{{ $t('Learn More') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="block block1" id="whatis">
      <div class="t1">{{ $t('high_quality_community') }}</div>
      <div class="t2">
        <!--                <span>与telegram深度融合</span><span>粉丝管理更简单</span><span>内容创作更方便</span><span>知识付费更便捷</span><span>优质创作者聚集地</span>-->
<!--        与telegram深度融合、粉丝管理更简单、内容创作更方便、知识付费更便捷、优质创作者聚集地-->
        {{ $t('telegramIntegration') }}

      </div>
      <div class="t3">
<!--        WeLink是创作者价值工具，是大咖云集的优质创作者聚集地，也是KOL以及网红连接粉丝的社群管理工具。在这里，KOL以及网红能够更好地管理粉丝，发布多种形式的优质内容，形成更好的内容沉淀。粉丝可以和偶像进行互动，私享偶像的优质创作内容，享受高质量的陪伴式体验。-->

        {{ $t('WeLink is a value tool for creators') }}
      </div>
    </div>


    <div class="block block2">
      <swiper
          class="my_swiper"
          :modules="modules"
          :navigation="{
                    nextEl: '.my_next',
                    prevEl: '.my_prev',
                }"
          :loop="true"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
      >
        <swiper-slide class="my_swiper_slide">
          <a href=""><img src="../../assets/index/s18.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s17.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s16.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s15.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s14.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s23.png" alt="" class="plogo" /></a>
        </swiper-slide>
        <swiper-slide class="my_swiper_slide">
          <a href=""><img src="../../assets/index/s13.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s12.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s11.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s10.svg" alt="" class="plogo" /></a>
          <a href=""><img src="../../assets/index/s9.svg" alt="" class="plogo" /></a>
        </swiper-slide>
      </swiper>
      <div class="my_prev" @click.stop="prevEl(item, index)"></div>
      <div class="my_next" @click.stop="nextEl(item, index)"></div>
    </div>


    <div class="block block3">
      <div class="t1">{{ $t('Our Future Looks Like This!') }}</div>
      <div class="center">
        <div class="b3_item">
          <div class="item_number">1</div>
          <div class="item_title">{{ $t('phase_one') }}</div>
          <div class="item_list">
            <p>{{ $t('whitelist_launch') }}</p>
            <p>{{ $t('ambassador_program_launch') }}</p>
            <p>{{ $t('nft_pass_sale') }}</p>
            <p>{{ $t('internal_product_development_testing') }}</p>
          </div>
        </div>
        <div class="b3_item">
          <div class="item_number">2</div>
          <div class="item_title">{{ $t('phase_two') }}</div>
          <div class="item_list">
            <p>{{ $t('security_audit') }}</p>
            <p>{{ $t('linkspace_bonus_nft_release') }}</p>
            <p>{{ $t('welink_mini_app_release') }}</p>
            <p>{{ $t('mcn_program_release') }}</p>
          </div>
        </div>
        <div class="b3_item">
          <div class="item_number">3</div>
          <div class="item_title">{{ $t('phase_three') }}</div>
          <div class="item_list">
            <p>{{ $t('video_feature_release') }}</p>
            <p>{{ $t('link_launch') }}</p>
            <p>{{ $t('early_user_airdrop') }}</p>
          </div>
        </div>
        <div class="b3_item">
          <div class="item_number">4</div>
          <div class="item_title">{{ $t('phase_four') }}</div>
          <div class="item_list">
            <p>{{ $t('welink_app_launch') }}</p>
            <p>{{ $t('video_live_feature_release') }}</p>
            <p>{{ $t('link_token_utilization') }}</p>
            <p>{{ $t('implement_additional_security_measures') }}</p>
          </div>
        </div>
        <!--                <div class="b3_item">-->
        <!--                    <div class="item_number">5</div>-->
        <!--                    <div class="item_title">第五阶段</div>-->
        <!--                    <div class="item_list">-->
        <!--                        <p>Limit order book</p>-->
        <!--                        <p>Margin trading</p>-->
        <!--                        <p>Gasless swaps</p>-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
    </div>

    <div class="block block4">
      <div class="t1">{{ $t('Advantages') }}</div>
      <div class="t2">{{ $t('Decentralized TON Blockchain and Open, Inclusive Telegram') }}</div>
      <div class="center">
        <div class="b4_item b4_item1">
          <div class="item_title">{{ $t('Deep Integration with Telegram') }}</div>
          <div class="item_desc">
            {{ $t('Built on the TON Blockchain') }} <br />
            {{ $t('Telegram Mini App seamlessly connects you with your fans') }}<br />
            <br />

          </div>
        </div>
        <div class="b4_item b4_item2">
          <div class="item_title">{{ $t('seamlessly connects you with your fans') }}</div>
          <div class="item_desc">
            {{ $t('compared_to_multiple_apps') }}<br />{{ $t('welink_built_into_telegram') }}<br />{{ $t('seamlessly_share_interact') }}<br />
          </div>
        </div>
        <div class="b4_item b4_item3">
          <div class="item_title">{{ $t('discover_follow_kols') }}</div>
          <div class="item_desc">
            {{ $t('kols_can_publish_content') }}<br />{{ $t('fans_can_interact') }}，<br />{{ $t('enjoy_private_creations') }}<br />{{ $t('enjoy_companion_experience') }}<br />
          </div>
        </div>
      </div>
    </div>

    <div class="block block5" id="explore">
      <div class="t1">{{ $t('explore') }}</div>
      <div class="t2">{{ $t('multiple_benefits') }}</div>
      <div class="center">
        <div class="b5_item">
          <img src="../../assets/index/icon4.png" alt="" class="item_icon" />
          <div class="item_title">{{ $t('monetization') }}</div>
          <div class="item_desc">
            {{ $t('multiple_monetization_methods') }}，{{ $t('earnings_for_creators_and_users') }} <br />
          </div>
          <a href="" class="item_link">
            Swap Coins
            <i class="icon_arr"></i>
          </a>
        </div>

        <div class="b5_item">
          <img src="../../assets/index/icon5.png" alt="" class="item_icon" />
          <div class="item_title">{{ $t('creator') }}</div>
          <div class="item_desc">
            {{ $t('purchase_creators_linkspace_bonus_nft') }}<br />
            {{ $t('earn_linkspace_revenue_with_creators') }} <br />

          </div>
          <a href="" class="item_link">
            Provide Liquidity
            <i class="icon_arr"></i>
          </a>
        </div>

        <div class="b5_item">
          <img src="../../assets/index/icon6.png" alt="" class="item_icon" />
          <div class="item_title">{{ $t('pay') }}</div>
          <div class="item_desc">
            {{ $t('easily_create_paid_linkspace') }}<br />
            {{ $t('enjoy_income_from_membership_fee_and_tips') }}<br />
            {{ $t('pay_with_ton_blockchain') }}<br />
            {{ $t('instant_withdrawal_after_receiving_payment') }}
          </div>
          <a href="" class="item_link">
            Request Listing
            <i class="icon_arr"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="block block6">
      <div class="center">
        <a href="" class="b6_item">
          <div class="item_title">{{ $t('Check our guide to enhance your welink experience') }}</div>
          <div class="item_link">{{ $t('User guide') }}<i class="icon_arr"></i></div>
        </a>
      </div>
    </div>


    <div class="block block7" id="developers">
      <div class="t2">{{ $t('Developers and liquidity providers') }}</div>
      <div class="center">
        <a href="" class="b7">
          <div class="t">{{ $t('WELINK on Blockchain') }}</div>
          <i class="icon_share"></i>
        </a>
        <a href="" class="b7">
          <div class="t">GitHub</div>
          <i class="icon_share"></i>
        </a>
        <a href="" class="b7">
          <div class="t">{{ $t('WELINK Documentation') }} </div>
          <i class="icon_share"></i>
        </a>
      </div>
    </div>


    "stay_in_touch": "Stay in touch",
    "see_feature_release": "to see feature release",
    "events_and_announcements": "events and announcements"

    <div class="block block8">
      <div class="t2"><span>{{ $t('stay_in_touch') }}</span> {{ $t('see_feature_release') }}, <br />{{ $t('events_and_announcements') }}</div>
      <div class="center">
        <div class="b8_line">
          <input class="input" type="text" :placeholder="$t('Email address')" />
          <button class="submit">{{ $t('Subscribe') }}</button>
        </div>
        <div class="b8_line">
          <a href="" class="b8_link">
            <img src="../../assets/index/s6.svg" alt="" />
            <p class="title">Channle</p>
          </a>
          <a href="" class="b8_link">
            <img src="../../assets/index/s5.svg" alt="" />
            <p class="title">Chat</p>
          </a>
          <a href="" class="b8_link">
            <img src="../../assets/index/s4.svg" alt="" />
            <p class="title">Twitter</p>
          </a>
          <a href="" class="b8_link">
            <img src="../../assets/index/s3.svg" alt="" />
            <p class="title">Github</p>
          </a>
          <a href="" class="b8_link">
            <img src="../../assets/index/s2.svg" alt="" />
            <p class="title">Discord</p>
          </a>
          <a href="" class="b8_link">
            <img src="../../assets/index/s1.svg" alt="" />
            <p class="title">Reddit</p>
          </a>
        </div>
      </div>
    </div>


    <div class="footer">
<!--      <div class="center">-->
<!--        <div class="left">-->
<!--          <img class="f_logo" src="../../assets/index/logo.svg" alt="" />-->
<!--          <div class="f_logo_text">Instantly swap Toncoin for any coin or token at the best rate</div>-->
<!--          <div class="language">-->
<!--            <i class="icon_flag"></i>-->
<!--            <span>ENG</span>-->
<!--            <i class="icon_down"></i>-->
<!--            <div class="select_wrap">-->
<!--              <div class="language_list">-->
<!--                <div class="language_item">English <i class="icon_select"></i></div>-->
<!--                <div class="language_item">Русский</div>-->
<!--                <div class="language_item">Bahasa Indonesia</div>-->
<!--                <div class="language_item">Tiếng Việt</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="middle">-->
<!--          <div class="f_link_g">-->
<!--            <div class="t">Tools</div>-->
<!--            <a href="" class="a">Trade/Swap</a>-->
<!--            <a href="" class="a">Pools</a>-->
<!--          </div>-->
<!--          <div class="f_link_g">-->
<!--            <div class="t">STON.fi</div>-->
<!--            <a href="" class="a">User guide</a>-->
<!--            <a href="" class="a">Blog</a>-->
<!--            <a href="" class="a">Whitepaper</a>-->
<!--            <a href="" class="a">Brand kit</a>-->
<!--            <a href="" class="a">Terms of use</a>-->
<!--            <a href="" class="a">Privacy policy</a>-->
<!--          </div>-->
<!--          <div class="f_link_g">-->
<!--            <div class="t">Developers</div>-->
<!--            <a href="" class="a">Documentation</a>-->
<!--            <a href="" class="a">Github</a>-->
<!--            <a href="" class="a">Grant program</a>-->
<!--            <div class="t ts">Support</div>-->
<!--            <a href="" class="a">Contact us</a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <div class="t">Community</div>-->
<!--          <div class="d">Join our growing community</div>-->
<!--          <input type="text" placeholder="Email address" class="input" />-->
<!--          <div class="submit">Subscript</div>-->
<!--          <div class="f">-->
<!--            <a href="" class="f_item">-->
<!--              <img src="../../assets/index/s6.svg" alt="" />-->
<!--            </a>-->
<!--            <a href="" class="f_item">-->
<!--              <img src="../../assets/index/s5.svg" alt="" />-->
<!--            </a>-->
<!--            <a href="" class="f_item">-->
<!--              <img src="../../assets/index/s4.svg" alt="" />-->
<!--            </a>-->
<!--            <a href="" class="f_item">-->
<!--              <img src="../../assets/index/s3.svg" alt="" />-->
<!--            </a>-->
<!--            <a href="" class="f_item">-->
<!--              <img src="../../assets/index/s2.svg" alt="" />-->
<!--            </a>-->
<!--            <a href="" class="f_item">-->
<!--              <img src="../../assets/index/s1.svg" alt="" />-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


      <div class="center top_line">
        <div class="f2_t">
          <img src="../../assets/index/s24.svg" alt="" class="f2_t_i" />
          <div class="f2_t_t">© 2024 WELINK</div>
        </div>
        <div class="f2_info">
          {{ $t('disclaimer') }}

<!--          Disclaimer: Nothing on this site is investment advice. All information is for informational purposes only. You should not construe-->
<!--          any such information or other material as legal, tax, investment, financial, or other advice. Nothing contained on our site-->
<!--          constitutes a solicitation, recommendation, endorsement, or offer by STON.fi or any third party service provider to buy or sell-->
<!--          any assets, digital coins and tokens, securities or other financial instruments in this or in in any other jurisdiction in which-->
<!--          such solicitation or offer would be unlawful under the securities laws of such jurisdiction. Please view-->
<!--          <span>Terms of use </span> for more information.-->
        </div>
      </div>
    </div>









  </div>


</template>

<script>

// export default {
//   name: 'HomePage',
//   methods: {
//     changeLanguage(lang) {
//       console.log('sssssss',lang)
//       this.$i18n.locale = lang;
//     }
//   }
// }
import { ref } from 'vue'
// import Swiper core and required modules
import { Navigation, EffectFade } from 'swiper/modules'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

// Import Swiper styles
export default {
  name: 'HomePage',
  data() {
    return {
      selectedLanguage: 'English', // 默认显示英语内容
      contents: {
        en: 'English',
        zh: '中文',
        ru: 'Русский',
        id: 'Konten dalam',
        vi: 'Nội dung'
      }
    };
  },

  methods: {
    // changeLanguage(lang) {
    //   console.log('sssssss',lang)
    //   this.$i18n.locale = lang;
    // },
    setLanguage(language) {
      console.log('language',language)

      this.$i18n.locale = language;
      var contents = this.contents;
      this.selectedLanguage = contents[language]; // Fix here: use contents[language]



    },
    getContent(language) {
      return this.contents[language];
    }
  },

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    const prevEl = (item, index) => {
      console.log(item);
      console.log(index);// 使用 item 参数
      // 在此处实现你的逻辑
    }
    const nextEl = (item, index) => {
      console.log(item);
      console.log(index);// 使用 item 参数
      // 在此处实现你的逻辑
    }
    const showMenu = ref(false)
    const menuTap = () => {
      console.log(showMenu.value)
      showMenu.value = !showMenu.value
    }

    return {
      showMenu,
      menuTap,
      onSwiper,
      onSlideChange,
      prevEl,
      nextEl,
      modules: [Navigation, EffectFade],
    }
  },
}


</script>

<style scoped lang="scss">
@media screen and (max-width: 1199px) {
  .center {
    width: 960px !important;
  }
  .banner {
    width: 100% !important;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .block2 {
    .my_swiper {
      width: 960px !important;
      margin: 0 auto !important;
    }
    .plogo {
      width: 140px !important;
      margin: 5px !important;
    }
  }
  .block3 {
    .center {
      justify-content: center !important;
      &:after {
        display: none !important;
      }
    }
    .b3_item {
      margin: 0 20px !important;
    }
  }
  .block4 {
    .b4_item {
      width: 293px !important;
      height: 313px !important;
      .item_title {
        padding-top: 10px !important;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 0 !important;
      }
      .item_desc {
        font-size: 14px !important;
      }
    }
  }
  .block5 {
    .b5_item {
      width: 293px !important;
      height: 313px !important;
      background-size: 293px 313px !important;
      padding: 20px !important;
      .item_link {
        margin-top: 10px !important;
      }
    }
  }
  .block7 {
    .b7 {
      width: 300px !important;
      padding: 0 20px !important;
    }
  }
}

@media screen and (max-width: 959px) {
  .center {
    width: 600px !important;
  }
  .block {
    .t2 {
      width: 387px !important;
      text-align: center !important;
      font-size: 32px !important;
    }
    .t3 {
      width: 607px !important;
    }
  }
  .header {
    height: 80px !important;
    .center {
      box-sizing: border-box !important;
      width: 100% !important;
      height: 80px !important;
      padding: 0 10px !important;
    }
    .menu {
      display: none !important;
    }
    .download_app {
      display: none !important;
    }
    .bt1 {
      display: block !important;
      width: 60px;
      height: 60px;
      background-image: url(../../assets/index/bt1.svg);
    }
    .bt2 {
      display: block !important;
      width: 60px;
      height: 60px;
      background-image: url(../../assets/index/bt2.svg);
    }
  }
  .mobile_menu {
    width: 100vw;
    height: calc(100vh - 80px);
    position: fixed;
    left: 0;
    top: 80px;
    overflow: hidden;
    z-index: -99999;
    background-color: #070707;
    opacity: 0;
    transition: opacity 0.3s linear;
    .mobile_menu_link {
      color: #fff;
      display: block;
      font-size: 40px;
      line-height: 70px;
      font-weight: 600;
      text-decoration: none;
      padding: 0 20px;
    }
  }
  .target {
    opacity: 1;
    z-index: 99999;
    transition: opacity 0.3s linear;
  }
  .banner {
    .cover_text {
      .t1,
      .t2 {
        font-size: 50px !important;
      }
    }
  }
  .block2 {
    margin-top: 50px !important;
    .my_swiper {
      width: 640px !important;
      height: 128px !important;
    }
    .my_swiper_slide {
      flex-wrap: wrap !important;
      height: 128px !important;
      .plogo {
        width: 160px !important;
      }
    }
  }
  .block3 {
    .center {
      .b3_item {
        width: 280px !important;
        height: 280px !important;
        &:nth-child(2n-1) {
          margin-left: 0 !important;
        }
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
        .item_title {
          margin-top: 15px !important;
        }
        .item_list {
          margin-top: 15px;
          p {
            margin-bottom: 6px !important;
          }
        }
      }
    }
  }
  .block4 {
    .center {
      flex-wrap: wrap;
      .b4_item {
        margin-bottom: 10px !important;
      }
    }
  }
  .block5 {
    .b5_item {
      margin-bottom: 15px !important;
    }
  }
  .block7 {
    .center {
      flex-wrap: wrap;
      .b7 {
        width: 293px !important;
        margin-bottom: 10px !important;
      }
    }
  }
  .block8 {
    .t2 {
      width: 576px !important;
      span {
        font-size: 32px !important;
      }
    }
  }
  .footer {
    .language {
      margin-top: 10px !important;
    }
    .middle {
      margin-top: 30px !important;
    }
  }
}

@media screen and (max-width: 639px) {
  .banner {
    .cover_text {
      .t1,
      .t2 {
        font-size: 40px !important;
      }
      .t2 {
        width: 420px !important;
        line-height: 48px !important;
      }
    }
  }
  .block {
    .t2 {
      font-size: 24px !important;
      line-height: 30px !important;
      span {
        font-size: 24px !important;
        line-height: 30px !important;
      }
    }
    .t3 {
      width: 440px !important;
      font-size: 16px !important;
      line-height: 26px !important;
    }
  }
}

@media screen and (max-width: 479px) {
  .header {
    height: 64px !important;
    .center {
      height: 64px !important;
    }
  }
  .center {
    max-width: 100% !important;
  }
  .block {
    .t2 {
      max-width: 100% !important;
    }
    .t3 {
      width: 80% !important;
      margin-left: 10% !important;
      margin-right: 10% !important;
    }
  }
  .block2 {
    .my_swiper {
      width: 100% !important;
      .my_swiper_slide {
        width: 100% !important;
      }
      .plogo {
        width: 110px !important;
      }
    }
  }
  .block3 {
    .center {
      width: 100%;
      .b3_item {
        width: 68% !important;
        height: 280px !important;
        margin: 0 0 10px !important;
        .item_title {
          margin-top: 15px !important;
          font-size: 24px !important;
        }
        .item_list {
          margin-top: 15px;
          p {
            margin-bottom: 6px !important;
          }
        }
      }
    }
  }
  .block4 {
    .center {
      display: flex !important;
      justify-content: center !important;
      flex-wrap: wrap !important;
      .b4_item {
        width: 320px !important;
        height: 341px !important;
        margin-bottom: 30px !important;
      }
    }
  }
  .block5 {
    .center {
      display: flex !important;
      justify-content: center !important;
      flex-wrap: wrap !important;
      .b5_item {
        width: 62% !important;
        height: 320px !important;
        margin-bottom: 30px !important;
        background-size: cover;
        background-repeat: no-repeat;
        .item_title {
          font-size: 24px !important;
        }
      }
    }
  }
  .block6 {
    .b6_item {
      width: 320px !important;
      padding: 20px !important;
      box-sizing: border-box !important;
      .item_title {
        font-size: 20px !important;
        line-height: 26px !important;
      }
    }
  }
  .block7 {
    .center {
      display: flex !important;
      justify-content: center !important;
      flex-wrap: wrap !important;
      .b7 {
        width: 320px !important;
        font-size: 18px !important;
      }
    }
  }
  .mobile_menu {
    .mobile_menu_link {
      font-size: 24px !important;
    }
  }
  .block8 {
    .b8_line {
      flex-wrap: wrap;
      .input {
        width: 320px !important;
      }
      .submit {
        width: 320px !important;
        margin-left: 0!important;
        margin-top: 10px!important;
      }
    }
  }
  .footer {
    .center {
      width: 320px!important;
      .middle {
        flex-wrap: wrap;
      }
    }
  }
}
.center {
  width: 1144px;
  margin: 0 auto;
}
.a_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  font-size: 16px;
  padding: 0 20px;
  background-color: rgba($color: #fff, $alpha: 0);
  color: #ccc;
  text-decoration: none;
  transition: all 0.3s;
  font-family: 'Inter', Arial, sans-serif;
  font-weight: 400;
  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.08);
  }
}
.header {
  width: 100%;
  height: 88px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  z-index: 1900;
  position: fixed;
  top: 0;
  left: 0;
  .center {
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .logo {
    display: block;
    width: 143px;
    img {
      display: block;
      width: 100%;
    }
  }
  .menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon_share {
      display: block;
      width: 14px;
      height: 14px;
      background-image: url('../../assets/index/s7.svg');
      background-size: 14px 14px;
      background-repeat: no-repeat;
      margin-right: 6px;
    }
  }
  .language {
    margin-left: 50px;
    position: relative;
    &:hover {
      .select_wrap {
        display: block;
      }
    }
    .select_wrap {
      display: none;
      width: 220px;
      position: absolute;
      left: -30px;
      top: 48px;
      padding-top: 30px;
      .language_list {
        background-color: #1b1b1e;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 10px 15px;
        .language_item {
          box-sizing: border-box;
          font-size: 16px;
          line-height: 40px;
          padding: 0 15px;
          border-radius: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &:hover {
            background-color: #242427;
          }
        }
        .icon_select {
          display: block;
          width: 12px;
          height: 12px;
          background-image: url('../../assets/index/s22.svg');
          background-size: 12px 12px;
          background-repeat: no-repeat;
          margin-left: 10px;
        }
      }
    }
    .icon_flag {
      display: block;
      width: 14px;
      height: 14px;
      background-image: url('../../assets/index/flag_eng.svg');
      background-size: 14px 14px;
      background-repeat: no-repeat;
    }
    span {
      font-size: 16px;
      color: #ccc;
      margin: 0 10px;
    }
    .icon_down {
      display: block;
      width: 8px;
      height: 8px;
      background-image: url('../../assets/index/s21.svg');
      background-size: 8px 8px;
      background-repeat: no-repeat;
      margin-top: -3px;
    }
  }
  .download_app {
    background-color: #017bff;
    margin-left: 10px;
    color: #fff;
    padding: 0 30px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background-color: #0797ff;
    }
    .icon_share2 {
      display: block;
      width: 20px;
      height: 20px;
      background-image: url('../../assets/index/s20.svg');
      background-size: 20px 20px;
      background-repeat: no-repeat;
      margin-right: 6px;
    }
  }
  .bt1 {
    display: none;
  }
}
.banner {
  width: 100%;
  height: 620px;
  position: relative;
  .video {
    position: absolute;
    width: 1440px;
    height: 620px;
    left: 50%;
    transform: translateX(-50%);
    video {
      width: 100%;
      height: 100%;
    }
    .cover_left {
      background: linear-gradient(90deg, rgba(7, 7, 7, 1), rgba(7, 7, 7, 0) 80%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .cover_right {
      background: linear-gradient(90deg, rgba(7, 7, 7, 0) 80%, rgba(7, 7, 7, 1));
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .cover_bottom {
      background: linear-gradient(rgba(7, 7, 7, 0) 80%, rgba(7, 7, 7, 1));
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .cover_text {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .t1 {
        color: transparent;
        background-image: linear-gradient(45deg, rgba(1, 123, 255, 1) 0%, rgba(15, 221, 178, 1) 96%);
        -webkit-background-clip: text;
        will-change: transform;
        font-size: 70px;
        font-family: 'Inter', Arial, sans-serif;
        font-weight: 700;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        margin-top: 100px;
      }
      .t2 {
        width: 740px;
        text-align: center;
        color: #ffffff;
        font-size: 70px;
        font-family: 'Inter', Arial, sans-serif;
        line-height: 84px;
        font-weight: 700;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
      }
      .btns {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        .b1 {
          padding: 0 30px;
          height: 56px;
          color: #fff;
          font-size: 16px;
          line-height: 56px;
          border-radius: 56px;
          background-color: #017bff;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #0797ff;
          }
        }
        .icon_share2 {
          display: block;
          width: 20px;
          height: 20px;
          background-image: url('../../assets/index/s20.svg');
          background-size: 20px 20px;
          background-repeat: no-repeat;
          margin-right: 6px;
          margin-right: 10px;
        }
        .b2 {
          padding: 0 30px;
          height: 56px;
          color: #fff;
          font-size: 16px;
          line-height: 56px;
          border-radius: 56px;
          background-color: rgba($color: #fff, $alpha: 0.08);
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-left: 15px;
          &:hover {
            background-color: #242526;
          }
        }
      }
    }
  }
}
.block {
  .t1 {
    color: transparent;
    background-image: linear-gradient(45deg, rgba(0, 122, 254, 1) 0%, rgba(3, 187, 203, 1) 100%);
    -webkit-background-clip: text;
    will-change: transform;
    font-size: 30px;
    font-family: 'Inter', Arial, sans-serif;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
  }
  .t2 {
    color: #ffffff;
    font-size: 40px;
    font-family: 'Inter', Arial, sans-serif;
    line-height: 48px;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-top: 10px;
  }
}
.block1 {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .t3 {
    width: 800px;
    margin-top: 30px;
    color: #cccccc;
    font-size: 20px;
    font-family: 'Inter', Arial, sans-serif;
    line-height: 32px;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    text-align: center;
  }
}
.block2 {
  position: relative;
  .my_swiper {
    height: 100px;
    color: #fff;
    margin-top: 50px;
    .my_swiper_slide {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .plogo {
      display: block;
      width: 160px;
      aspect-ratio: 160 / 60;
      opacity: 0.5;
      margin: 20px;
      &:hover {
        opacity: 1;
      }
    }
  }
  .my_next {
    width: 22px;
    height: 42px;
    background-image: url('../../assets/index/right.svg');
    background-size: cover;
    position: absolute;
    right: 10px;
    top: 29px;
    z-index: 999;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .my_prev {
    width: 22px;
    height: 42px;
    background-image: url('../../assets/index/left.svg');
    background-size: cover;
    transform: rotate(180deg);
    position: absolute;
    left: 10px;
    top: 29px;
    z-index: 999;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}
.block3 {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .center {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    &:after {
      content: '';
      width: 352px;
    }
    .b3_item {
      width: 352px;
      height: 350px;
      background-image: url('../../assets/index/list.svg');
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 40px 30px;
      margin-bottom: 40px;
      .item_number {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #017bff;
        color: #fff;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
      }
      .item_title {
        font-size: 32px;
        line-height: 40px;
        color: #fff;
        margin-top: 30px;
        font-weight: 600;
      }
      .item_list {
        margin-top: 20px;
        p {
          font-size: 16px;
          line-height: 24px;
          color: #017bff;
          position: relative;
          padding-left: 15px;
          margin-bottom: 10px;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: #017bff;
          }
        }
      }
    }
  }
}
.block4 {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .center {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .b4_item {
      width: 352px;
      height: 376px;
      background-size: 100% 100%;
      .item_title {
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 10px;
        font-weight: 600;
      }
      .item_desc {
        font-size: 16px;
        line-height: 24px;
        color: #ccc;
        text-align: center;
      }
    }
    .b4_item1 {
      background-image: url('../../assets/index/icon1.png');
    }
    .b4_item2 {
      background-image: url('../../assets/index/icon2.png');
    }
    .b4_item3 {
      background-image: url('../../assets/index/icon3.png');
    }
  }
}
.block5 {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .center {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    .b5_item {
      width: 352px;
      height: 376px;
      background-image: url('../../assets/index/swap.svg');
      background-size: 352px 376px;
      box-sizing: border-box;
      padding: 50px 50px;
      margin-bottom: 40px;
      .item_icon {
        width: 80px;
        height: 80px;
      }
      .item_title {
        font-size: 32px;
        line-height: 40px;
        color: #fff;
        margin-top: 30px;
        font-weight: 600;
      }
      .item_desc {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        height: 48px;
        color: #ccc;
        text-align: left;
      }
      .item_link {
        font-size: 16px;
        line-height: 90px;
        color: #017bff;
        font-weight: 600;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        margin-top: 30px;
        .icon_arr {
          display: block;
          width: 20px;
          height: 10px;
          background-image: url('../../assets/index/arr.svg');
          background-size: 20px 10px;
          margin-left: 20px;
        }
      }
    }
  }
}
.block6 {
  margin-top: 100px;
  .center {
    display: flex;
    justify-content: center;
  }
  .b6_item {
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px 0;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover {
      border-color: rgba($color: #fff, $alpha: 0.5);
    }
  }
  .item_title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  .item_link {
    font-size: 16px;
    line-height: 24px;
    color: #017bff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin-top: 25px;
    .icon_arr {
      display: block;
      width: 20px;
      height: 10px;
      background-image: url('../../assets/index/arr.svg');
      background-size: 20px 10px;
      margin-left: 20px;
    }
  }
}
.block7 {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .center {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .b7 {
      width: 352px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 0 30px;
      border-radius: 20px;
      transition: all 0.3s ease-in-out;
      &:hover {
        border-color: rgba($color: #fff, $alpha: 0.5);
        .icon_share {
          opacity: 0.8;
        }
      }
      .t {
        font-size: 20px;
        line-height: 68px;
        color: #fff;
        font-weight: 400;
      }
      .icon_share {
        width: 20px;
        height: 20px;
        background-image: url('../../assets/index/s7.svg');
        background-size: cover;
        opacity: 0.2;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
.block8 {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .t2 {
    text-align: center;
    span {
      color: transparent;
      background-image: linear-gradient(46deg, rgba(1, 123, 255, 1) 0%, rgba(4, 157, 229, 1) 100%);
      -webkit-background-clip: text;
      will-change: transform;
      font-size: 40px;
      font-family: 'Inter', Arial, sans-serif;
      line-height: 1.2;
      font-weight: 700;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    .b8_line {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
    }
    .input {
      width: 330px;
      height: 56px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #2d2d30;
      border: 1px solid rgba(255, 255, 255, 0);
      padding: 0 20px;
      color: #bababa;
      font-size: 16px;
      &:focus {
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
    .submit {
      width: 150px;
      height: 56px;
      font-size: 16px;
      line-height: 56px;
      background-color: #017bff;
      color: #fff;
      text-align: center;
      font-weight: 700;
      border-radius: 28px;
      outline: none;
      box-sizing: border-box;
      border: 0;
      margin-left: 20px;
      cursor: pointer;
    }
    .b8_link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      text-decoration: none;
      width: 55px;
      img {
        display: block;
        width: 48px;
        height: 48px;
        opacity: 0.4;
        transition: all 0.1s ease-in-out;
      }
      .title {
        font-size: 14px;
        line-height: 24px;
        color: #9c9c9c;
        margin-top: 10px;
        transition: all 0.1s ease-in-out;
      }
      &:hover {
        img {
          opacity: 1;
        }
        .title {
          color: #fff;
        }
      }
    }
  }
}
.footer {
  padding-top: 100px;
  .center {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 244px;
      .f_logo {
        width: 140px;
      }
      .f_logo_text {
        font-size: 16px;
        line-height: 24px;
        color: #ccc;
        font-weight: 400;
        margin-top: 10px;
      }
      .language {
        margin-top: 50px;
        width: 114px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        font-size: 16px;
        padding: 0 20px;
        background-color: rgba(255, 255, 255, 0);
        color: #ccc;
        text-decoration: none;
        transition: all 0.3s;
        font-family: 'Inter', Arial, sans-serif;
        font-weight: 400;
        cursor: pointer;
        position: relative;
        &:hover {
          background-color: rgba(255, 255, 255, 0.08);
          .select_wrap {
            display: block;
          }
        }
        .select_wrap {
          display: none;
          width: 220px;
          position: absolute;
          left: -30px;
          top: 48px;
          padding-top: 30px;
          .language_list {
            background-color: #1b1b1e;
            border-radius: 20px;
            box-sizing: border-box;
            padding: 10px 15px;
            .language_item {
              box-sizing: border-box;
              font-size: 16px;
              line-height: 40px;
              padding: 0 15px;
              border-radius: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              &:hover {
                background-color: #242427;
              }
            }
            .icon_select {
              display: block;
              width: 12px;
              height: 12px;
              background-image: url('../../assets/index/s22.svg');
              background-size: 12px 12px;
              background-repeat: no-repeat;
              margin-left: 10px;
            }
          }
        }
        .icon_flag {
          display: block;
          width: 14px;
          height: 14px;
          background-image: url('../../assets/index/flag_eng.svg');
          background-size: 14px 14px;
          background-repeat: no-repeat;
        }
        span {
          font-size: 16px;
          color: #ccc;
          margin: 0 10px;
        }
        .icon_down {
          display: block;
          width: 8px;
          height: 8px;
          background-image: url('../../assets/index/s21.svg');
          background-size: 8px 8px;
          background-repeat: no-repeat;
          margin-top: -3px;
        }
      }
    }
    .middle {
      display: flex;
      justify-content: space-between;
      .f_link_g {
        width: 160px;
        .t {
          font-size: 20px;
          line-height: 28px;
          color: #fff;
          font-weight: 600;
          padding-bottom: 18px;
        }
        .ts {
          margin-top: 20px;
        }
        .a {
          display: block;
          font-size: 16px;
          line-height: 32px;
          color: #9b9b9b;
          text-decoration: none;
          transition: all 0.1s ease-in-out;
          &:hover {
            color: #017bff;
          }
        }
      }
    }
    .right {
      .t {
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        font-weight: 600;
        padding-bottom: 18px;
      }
      .d {
        font-size: 16px;
        line-height: 24px;
        color: #ccc;
      }
      .input {
        width: 280px;
        height: 40px;
        margin-top: 20px;
        box-sizing: border-box;
        border: 0;
        outline: none;
        background-color: #2d2d30;
        font-size: 16px;
        line-height: 40px;
        color: #fff;
        border-radius: 10px;
        padding: 0 20px;
      }
      .submit {
        width: 280px;
        height: 40px;
        background-color: #017bff;
        color: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 40px;
        margin-top: 10px;
        border-radius: 20px;
      }
      .f {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        .f_item {
          width: 32px;
          height: 32px;
          opacity: 0.5;
          transition: all 0.1s ease-in-out;
          &:hover {
            opacity: 1;
          }
          img {
            display: block;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
  .top_line {
    margin-top: 20px;
    border-top: 1px solid rgba($color: #fff, $alpha: 0.12);
    display: block;
  }
  .f2_t {
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .f2_t_t {
      font-size: 16px;
      line-height: 24px;
      color: #9b9b9b;
    }
  }
  .f2_info {
    padding: 30px 0;
    font-size: 13px;
    line-height: 20px;
    color: #606060;
    span {
      color: #9b9b9b;
    }
  }
}
</style>
