<template>
  <el-config-provider :locale="lang">
    <router-view class="t-body"></router-view>
  </el-config-provider>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<!--<style>-->
<!--#app {-->
<!--  font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--  //margin-top: 60px;-->
<!--}-->
<!--</style>-->
<style>
.t-body {
  min-height: 100vh;
  background-color: #070707;
}
body {
  display: block;
  margin: 0;
}
</style>