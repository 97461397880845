import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import i18n from './i18n'
import MainHeader from './components/header.vue';

import router from './router'; // 引入路由实例  

const app = createApp(App)
// 使用路由实例  
app.use(router);  
app.use(ElementPlus)
app.use(i18n)
app.component('main-header', MainHeader);

app.mount('#app')
